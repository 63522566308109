.navBar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 30px 20px 30px;
}

.buttonRow {
    display: flex;
    flex-direction: row;
    gap: 24px
}

.root {
    display: flex;
    flex-direction: column;
    background: #F4F4F4;
    min-height: 100vh;
    overflow: scroll;
}

.footer {
    margin-top: auto;
}
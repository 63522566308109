.cardsGrid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
}

.card {
    display: flex;
    flex: 0 0 max(30%, 350px);
    flex-direction: column;
    border-radius: 5px;
    box-shadow: 5px 5px 5px #cccccc;
    background: #FFFFFF;
    overflow: hidden;
    cursor: pointer;
}

.card:hover {
    background: #EEEEEE;
}

.cardInner {
    margin: 24px;
}

.highlight {
    width: 100%;
    height: 12px;
}

.titleParent {
    display: flex;
    flex-direction: row;
    align-content: center;
    gap: 10px;
}
.landingPage {
    display: flex;
    align-items: center;
    flex-direction: column;
    margin: 20px 10% 20px 10%;
}

.tabParent {
    align-self: flex-start;
    width: 100%;
}

.airtableEmbedded {
    background: transparent;
    border: 1px solid #ccc;
}

.infoSection {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-evenly;
    gap: 16px;
}
